import React from 'react'
import App from 'next/app'
import Sentry from '../lib/sentry'

export default class MyApp extends App {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo.componentStack).forEach(key =>
        scope.setExtra(key, (errorInfo as any)[key]),
      )

      Sentry.captureException(error)
    })

    super.componentDidCatch(error, errorInfo)
  }
}
